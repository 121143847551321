<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${tbl.length > 0 ? `(${tbl.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="login.permissions.rss_doctors_validation.read"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                dark
                :loading="btn_xls_ldg"
                @click.prevent="downloadXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'descargar tabla XLS'" />
          </v-tooltip>
          <v-tooltip v-if="true" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="purple"
                dark
                :to="{
                  name: 'servicios.rs_services_survey',
                }"
              >
                <v-icon> mdi-file-certificate </v-icon>
              </v-btn>
            </template>
            <span v-text="'Encuestas'" />
          </v-tooltip>
          <v-tooltip v-if="true" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="pink"
                dark
                :to="{
                  name: 'servicios.rs_services_confirm_date',
                }"
              >
                <v-icon> mdi-file-sign </v-icon>
              </v-btn>
            </template>
            <span v-text="'Confirmaciones'" />
          </v-tooltip>
          <v-tooltip
            v-if="login.permissions.rss_doctors_validation.read"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="warning"
                :to="{
                  name: 'servicios.doctors_validation',
                }"
              >
                <v-icon> mdi-doctor </v-icon>
              </v-btn>
            </template>
            <span v-text="'Validación Médicos'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              login.permissions.rss_rs_refounds &&
              login.permissions.rss_rs_refounds.read
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="brown"
                dark
                :to="{
                  name: 'servicios.rs_refounds',
                }"
              >
                <v-icon> mdi-account-cash </v-icon>
              </v-btn>
            </template>
            <span v-text="'Reembolsos'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.rss.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{
              name: `${page_route}.agregar`,
            }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" v-if="new_id && new_alert">
          <v-alert v-model="new_alert" type="info" dismissible dense>
            <div
              class="text-center"
              v-text="
                `La solicitud de servicio ${new_id} fue registrada correctamente`
              "
            />
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-autocomplete
            label="Tipo"
            v-model="rss_search.rs_type_id"
            :items="rs_types"
            item-value="id"
            :item-text="(v) => v.type"
            :loading="rs_types_loading"
            dense
            :disabled="tbl.length > 0 || loading"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Estado"
            v-model="active"
            :items="active_options"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="tbl.length > 0 || loading"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Proceso"
            v-model="pending"
            :items="pending_options"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="tbl.length > 0 || loading"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            dense
            :disabled="loading"
          />
        </v-col>
        <v-col v-if="!active" cols="12">
          <v-row dense>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="ID/SM"
                v-model="rss_search.enrollment"
                type="text"
                dense
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Nombre"
                v-model="rss_search.name"
                type="text"
                dense
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Apellido paterno"
                v-model="rss_search.first_surname"
                type="text"
                dense
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Apellido materno"
                v-model="rss_search.second_surname"
                type="text"
                dense
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-autocomplete
                label="Aseguradora"
                v-model="rss_search.insurance_id"
                :items="insurances"
                item-value="id"
                :item-text="(item) => item.name"
                dense
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <DatePicker
                label="Fecha de ingreso inicio"
                :model.sync="rss_search.start_date"
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <DatePicker
                label="Fecha de ingreso final"
                :model.sync="rss_search.end_date"
                :disabled="tbl.length > 0 || loading"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="tbl.length == 0"
            tile
            block
            color="info"
            @click="getDataTable"
            :loading="loading"
            :disabled="loading"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
          <v-btn v-else tile block @click="tbl = []">
            Cambiar parametros
            <v-icon right> mdi-update </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="tbl"
            :search="search"
            :loading="loading"
            :item-class="itemRowBackground"
            show-expand
            dense
          >
            <template v-slot:item.index="{ item }">
              <b v-text="item.index" />
            </template>
            <template v-slot:item.specialty_name="{ item }">
              {{ item.specialty_name ? item.specialty_name : "N/A" }}
            </template>

            <template v-slot:item.enrollment="{ item }">
              <span
                :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                v-text="item.enrollment"
              />
            </template>
            <template v-slot:item.insured_full_name="{ item }">
              <span
                :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                v-text="item.insured_full_name"
              />
            </template>
            <template v-slot:item.contractor="{ item }">
              <span
                :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                v-text="item.contractor"
              />
            </template>
            <template v-slot:item.rs_type_name="{ item }">
              {{ item.rs_type_name }}
              <v-icon
                v-if="item.rs_type_id == 9 && item.rs_comment_detail_pending"
                right
                small
                color="warning"
              >
                mdi-email
              </v-icon>
            </template>
            <template v-slot:item.phone_medical="{ item }">
              <span v-text="item.phone_medical ? 'SI' : 'NO'" />
            </template>
            <template v-slot:item.external="{ item }">
              <span v-text="item.external ? 'SI' : 'NO'" />
            </template>
            <template v-slot:item.medical_revision="{ item }">
              <span
                :class="
                  item.rs_type_id == 4 || item.medical_revision
                    ? ''
                    : 'orange--text text--darken-3'
                "
                v-text="
                  item.rs_type_id == 4
                    ? '-'
                    : item.medical_revision
                    ? 'SI'
                    : 'NO'
                "
              />
            </template>
            <template v-slot:item.insured.medical_treatment="{ item }">
              {{
                item.insured
                  ? item.insured.medical_treatment
                    ? "SI"
                    : "NO"
                  : "-"
              }}
            </template>
            <template v-slot:item.services="{ item }">
              <div v-for="(v, i) in item.services" :key="i" class="fs-small">
                <b v-text="`${i + 1}. `" />
                <span>
                  {{ v.folio }}
                  <v-icon x-small color="success" v-if="v.url_survey">
                    mdi-file-table
                  </v-icon>
                  {{ " | " }}
                </span>
                <span
                  v-if="v.municipality"
                  v-text="`${v.municipality}, ${v.entity} | `"
                />
                <span
                  :class="
                    v.letter_generated_cancelled
                      ? 'red--text'
                      : v.refused
                      ? 'warning--text'
                      : v.letter_generated
                      ? 'green--text'
                      : ''
                  "
                  v-text="v.specialty_provider_type"
                />
                <span v-text="' | '" />
                <span
                  :class="
                    v.doctor_provider_name ? '' : 'orange--text text--darken-3'
                  "
                  v-text="
                    `${
                      v.doctor_provider_name
                        ? v.doctor_provider_name
                        : 'PENDIENTE'
                    }`
                  "
                />
                <span v-if="v.agreement" v-text="` | Conv.`" />
                <span
                  v-if="v.confirm_consultation && item.rs_type_id != 4"
                  v-text="` | C.C. `"
                />
                <span
                  v-if="v.confirm_consultation && item.rs_type_id != 4"
                  :class="
                    v.response_confirm_consultation !== null
                      ? v.response_confirm_consultation
                        ? 'green--text'
                        : 'red--text'
                      : 'orange--text text--darken-3'
                  "
                  v-text="
                    v.response_confirm_consultation !== null
                      ? v.response_confirm_consultation &&
                        v.confirm_consultation_by
                        ? `SI (${v.confirm_consultation_by.email})`
                        : 'NO'
                      : 'PENDIENTE'
                  "
                />
                <span v-if="v.benefits_revisions" v-text="` | V.P. `" />
                <span
                  v-if="v.benefits_revisions"
                  class="orange--text text--darken-3"
                  v-text="`(${v.benefits_revisions})`"
                />
                <span
                  v-if="v.pharmacy_dispatch != false"
                  v-text="` | Surt. `"
                />
                <span
                  v-if="v.pharmacy_dispatch != false"
                  :class="
                    v.pharmacy_dispatch ? '' : 'orange--text text--darken-3'
                  "
                  v-text="v.pharmacy_dispatch ? 'SI' : 'PENDIENTE'"
                />
                <span
                  v-if="v.letter_generated"
                  v-text="
                    ` | C.G. ${v.letter_generated} (${v.letter_generated_by.email})`
                  "
                />
                <span
                  v-if="v.rs_municipality"
                  v-text="
                    ` | Búsq. ${v.rs_municipality.municipality}, ${v.rs_entity.entity}`
                  "
                />
              </div>
            </template>

            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip
                  top
                  v-if="
                    login.permissions.rs_administratives.read &&
                    login.role_id != 7 &&
                    login.role_id != 8
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-badge
                      class="m-btn-badge"
                      :color="
                        getColorBadge(item.total_administratives, 'indigo')
                      "
                      :content="item.total_administratives.toString()"
                      overlap
                    >
                      <v-btn
                        :color="
                          getColorBadge(item.total_administratives, 'indigo')
                        "
                        :to="{
                          name: `${page_route}.administrativo`,
                          params: { id: item.id },
                        }"
                        v-on="on"
                        x-small
                        dark
                        fab
                      >
                        <v-icon v-text="'mdi-note-multiple'" />
                      </v-btn>
                    </v-badge>
                  </template>
                  <span v-text="'Seg. Administrativo'" />
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    login.permissions.rs_letters.read &&
                    item.rs_type_id != 4 &&
                    ((item.insured_id &&
                      item.accident !== null &&
                      item.initial !== null) ||
                      (item.insured_id &&
                        item.insured.contractor &&
                        !item.insured.contractor.medical_monitoring) ||
                      (item.insured_id && item.hour_validation))
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-badge
                      :color="
                        getColorBadgeService(
                          item.total_services,
                          item.total_services_pending,
                          'brown darken-1'
                        )
                      "
                      :content="item.total_services.toString()"
                      class="m-btn-badge"
                      overlap
                    >
                      <v-btn
                        :color="
                          getColorBadge(item.total_services, 'brown darken-1')
                        "
                        :to="{
                          name: `${page_route}.cartas`,
                          params: { id: item.id },
                        }"
                        v-on="on"
                        x-small
                        dark
                        fab
                      >
                        <v-icon v-text="'mdi-file-certificate'" />
                      </v-btn>
                    </v-badge>
                  </template>
                  <span v-text="'Cartas'" />
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    login.permissions.rs_medical_revisions.read &&
                    //!item.discharged &&
                    item.rs_type_id != 2 &&
                    item.rs_type_id != 4 &&
                    item.rs_type_id != 5 &&
                    item.insured_id &&
                    login.role_id != 7 &&
                    login.role_id != 8
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :color="
                        item.accident !== null && item.initial !== null
                          ? 'pink darken-2'
                          : 'grey'
                      "
                      :to="{
                        name: `${page_route}.seguimiento`,
                        params: { id: item.id },
                      }"
                      class="m-btn"
                      v-on="on"
                      x-small
                      dark
                      fab
                    >
                      <v-icon v-text="'mdi-medical-bag'" />
                    </v-btn>
                  </template>
                  <span v-text="'Seguimiento médico'" />
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    login.permissions.rs_medical_revisions.read &&
                    //!item.discharged &&
                    item.rs_type_id == 2 &&
                    item.insured_id &&
                    login.role_id != 7 &&
                    login.role_id != 8 &&
                    item.accident == null &&
                    item.initial == null &&
                    item.rs_refound_bills == true &&
                    (!item.rs_refound_rejected ||
                      item.rs_refound_rejected.response)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="grey"
                      :to="{
                        name: `${page_route}.reembolso_seg_medico`,
                        params: { id: item.id },
                      }"
                      class="m-btn"
                      v-on="on"
                      x-small
                      dark
                      fab
                    >
                      <v-icon> mdi-medical-bag </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Reembolso seguimiento médico'" />
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    login.permissions.rs_medical_revisions.read &&
                    item.rs_type_id == 2 &&
                    item.insured_id &&
                    login.role_id != 7 &&
                    login.role_id != 8 &&
                    item.rs_refound_bills == false &&
                    item.total_services == 0 &&
                    (!item.rs_refound_rejected ||
                      item.rs_refound_rejected.response)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      class="m-btn"
                      dark
                      color="warning"
                      :to="{
                        name: `${page_route}.reembolso`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon> mdi-account-cash </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Reembolso documentación'" />
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    (login.role_id === 1 || login.role_id === 2) &&
                    item.permit_discharge &&
                    !item.discharged
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click.prevent="dischargedSubmit(item.id, item.folio)"
                      color="success"
                      class="m-btn"
                      v-on="on"
                      x-small
                      dark
                      fab
                    >
                      <v-icon v-text="'mdi-check'" />
                    </v-btn>
                  </template>
                  <span v-text="'Terminar'" />
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      :to="{
                        name: `${page_route}.detalle`,
                        params: { id: item.id },
                      }"
                      v-on="on"
                      x-small
                      dark
                      fab
                    >
                      <v-icon v-text="'mdi-file-eye'" />
                    </v-btn>
                  </template>
                  <span v-text="'Detalle'" />
                </v-tooltip>
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td
                v-if="item.services.length > 0"
                :colspan="headers.length"
                class="pt-2 pb-5"
              >
                <b class="fs-small" v-text="'Servicios'" />
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-text="'#'" />
                        <th v-text="'Folio'" />
                        <th v-text="'Ciudad'" />
                        <th v-text="'Especialidad / Proveedor'" />
                        <th v-text="'Nombre'" />
                        <th v-text="'Convenio'" />
                        <th v-text="'Confirm. consulta'" />
                        <th v-text="'Valid. prestaciones'" />
                        <th v-text="'Surtida'" />
                        <th v-text="'Cobro factura'" />
                        <th v-text="'Carta generada'" />
                        <th v-text="'Búsqueda'" />
                        <th v-text="''" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(service, i) in item.services" :key="i">
                        <td class="fs-small font-weight-bold" v-text="i + 1" />
                        <td class="fs-small">
                          {{ service.folio }}
                          <v-icon
                            small
                            color="success"
                            v-if="service.url_survey"
                          >
                            mdi-file-table
                          </v-icon>
                        </td>
                        <td
                          class="fs-small"
                          v-text="
                            service.municipality
                              ? `${service.municipality}, ${service.entity}`
                              : '-'
                          "
                        />
                        <td class="fs-small">
                          <span
                            :class="
                              v.letter_generated_cancelled
                                ? 'red--text'
                                : v.refused
                                ? 'warning--text'
                                : service.letter_generated
                                ? 'green--text'
                                : ''
                            "
                            v-text="service.specialty_provider_type"
                          />
                        </td>
                        <td class="fs-small">
                          <span
                            :class="
                              service.doctor_provider_name
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="
                              `${
                                service.doctor_provider_name
                                  ? service.doctor_provider_name
                                  : 'PENDIENTE'
                              }`
                            "
                          />
                        </td>
                        <td class="fs-small">
                          <span v-if="service.agreement" v-text="'SI'" />
                          <span v-else v-text="'-'" />
                        </td>
                        <td class="fs-small">
                          <span
                            v-if="
                              service.confirm_consultation &&
                              item.rs_type_id != 4
                            "
                            :class="
                              service.response_confirm_consultation !== null
                                ? service.response_confirm_consultation
                                  ? 'green--text'
                                  : 'red--text'
                                : 'orange--text text--darken-3'
                            "
                            v-text="
                              service.response_confirm_consultation !== null
                                ? service.response_confirm_consultation
                                  ? `SI (${service.confirm_consultation_by.email})`
                                  : 'NO'
                                : 'PENDIENTE'
                            "
                          />
                          <span v-else v-text="'-'" />
                        </td>
                        <td class="fs-small">
                          <span
                            v-if="service.benefits_revisions"
                            class="orange--text text--darken-3"
                            v-text="`(${service.benefits_revisions})`"
                          />
                          <span v-else v-text="'-'" />
                        </td>
                        <td class="fs-small">
                          <span
                            v-if="service.pharmacy_dispatch != false"
                            :class="
                              service.pharmacy_dispatch
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="
                              service.pharmacy_dispatch ? 'SI' : 'PENDIENTE'
                            "
                          />
                          <span v-else v-text="'-'" />
                        </td>
                        <td class="fs-small">
                          <span
                            v-if="service.rs_service_insured_bill"
                            v-text="
                              service.rs_service_insured_bill.stamp_folio
                                ? 'SI'
                                : 'NO'
                            "
                          />
                          <span v-else v-text="'-'" />
                        </td>
                        <td class="fs-small">
                          <span
                            v-text="
                              service.letter_generated
                                ? `${service.letter_generated} (${service.letter_generated_by.email})`
                                : '-'
                            "
                          />
                        </td>
                        <td class="fs-small">
                          <span
                            v-text="
                              service.rs_municipality
                                ? `${service.rs_municipality.municipality}, ${service.rs_entity.entity}`
                                : '-'
                            "
                          />
                        </td>
                        <td width="105px">
                          <v-tooltip
                            v-if="
                              item.rs_type_id == 4 &&
                              !service.rs_service_insured_bill &&
                              service.consulting_room_provider &&
                              service.consulting_room_provider.payment
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="warning"
                                v-on="on"
                                x-small
                                dark
                                fab
                                @click="paymentDialog(item, i)"
                              >
                                <v-icon> mdi-currency-usd </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Cobrar'" />
                          </v-tooltip>

                          <v-tooltip
                            v-if="
                              item.rs_type_id == 4 &&
                              service.rs_service_insured_bill
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="info"
                                v-on="on"
                                x-small
                                dark
                                fab
                                @click="
                                  paymentInfoDialog(
                                    service.rs_service_insured_bill.id
                                  )
                                "
                              >
                                <v-icon> mdi-currency-usd </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Detalle de cobro'" />
                          </v-tooltip>

                          <!-- <v-tooltip
                            v-if="
                              login.permissions.rss.create &&
                              service.rs_service_insured_bill &&
                              service.rs_service_insured_bill.stamp_folio &&
                              !service.rs_service_bill
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="ml-1"
                                color="amber darken-2"
                                v-on="on"
                                x-small
                                dark
                                fab
                                :to="{
                                  name: 'servicios.documentacion',
                                  params: { rs_service_id: service.id },
                                }"
                              >
                                <v-icon> mdi-file-code </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Cargar documentación'" />
                          </v-tooltip> -->

                          <v-tooltip
                            v-if="
                              item.rs_type_id == 4 &&
                              (!service.consulting_room_provider ||
                                !service.consulting_room_provider.payment)
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                small
                                color="orange"
                                v-text="'mdi-alert'"
                              />
                            </template>
                            <span
                              v-text="
                                !service.consulting_room_provider
                                  ? 'No se puede realizar cobro. Consultorio médico no registrado o asignado a un Proveedor'
                                  : !service.consulting_room_provider.payment
                                  ? 'No se puede realizar cobro. Porcentaje cobro particulares no configurado en Provedoor'
                                  : ''
                              "
                            />
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="letter_dialog" scrollable persistent max-width="500px">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`${new_id} | Carta Exprés`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="letter_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      La carta exprés ha sido generada
                      <br />
                      <small>
                        En caso de consultarla posteriormente ir a el área de
                        detalle de la solicitud de servicio
                      </small>
                    </v-col>
                    <v-col cols="12">
                      <v-btn block color="success" v-text="'Ver'" small />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="service_payment_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card
        tile
        :disabled="service_payment_dialog_loading"
        :loading="service_payment_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="service_payment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="service_payment_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`SERVICIO | ${service_payment_data.folio_full}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. creación"
                        :value="service_payment_data.created_at"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Asegurado"
                        :value="service_payment_data.insured_full_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Médico"
                        :value="service_payment_data.doctor_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="service_payment_data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="service_payment_data.provider_trade_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Monto"
                        :value="
                          numberFormat(service_payment_data.benefit_amount)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'COBRO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_service_payment_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Paciente | C. electrónico*"
                          v-model="service_payment_data.email"
                          type="text"
                          maxlength="80"
                          dense
                          counter
                          :rules="rules.email"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Monto*"
                          v-model="service_payment_data.pay_amount"
                          type="number"
                          :rules="rules.required"
                          dense
                          @input="paymentDialogInsurancePayAmount"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          login.permissions.rs_services_insured_bills.update
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Monto a cobrar*"
                          v-model="service_payment_data.insurance_pay_amount"
                          type="number"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-switch
                          class="mt-1 pt-0"
                          :label="'Factura'"
                          v-model="service_payment_data.bill"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="service_payment_data.bill" dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Razón social*"
                          v-model="service_payment_data.legal_name"
                          type="text"
                          maxlength="100"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="RFC*"
                          v-model="service_payment_data.code_tax"
                          type="text"
                          maxlength="13"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Código postal*"
                          v-model="service_payment_data.legal_zip"
                          type="text"
                          maxlength="5"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Régimen*"
                          v-model="service_payment_data.fiscal_regime_id"
                          :items="fiscal_regimes"
                          item-value="id"
                          :item-text="(v) => `${v.code} | ${v.fiscal_regime}`"
                          :loading="fiscal_regimes_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Uso CFDI*"
                          v-model="service_payment_data.fiscal_use_id"
                          :items="fiscal_uses"
                          item-value="id"
                          :item-text="(v) => `${v.code} | ${v.fiscal_use}`"
                          :loading="fiscal_uses_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Forma de pago*"
                          v-model="
                            service_payment_data.fiscal_method_payment_id
                          "
                          :items="fiscal_method_payments"
                          item-value="id"
                          :item-text="
                            (v) => `${v.code} | ${v.fiscal_method_payment}`
                          "
                          :loading="fiscal_method_payments_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 4 ||
                          service_payment_data.fiscal_method_payment_id == 18
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Núm. de aprobación*"
                          v-model="service_payment_data.approval_number"
                          type="text"
                          maxlength="10"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 4 ||
                          service_payment_data.fiscal_method_payment_id == 18
                        "
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-text-field
                          label="Tarjeta (4 últ. dig.)*"
                          v-model="service_payment_data.card"
                          type="text"
                          maxlength="4"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          service_payment_data.fiscal_method_payment_id == 1 ||
                          service_payment_data.fiscal_method_payment_id == 3
                        "
                        cols="12"
                        xs="12"
                        md="6"
                      >
                        <v-text-field
                          label="Referencia*"
                          v-model="service_payment_data.reference"
                          type="text"
                          maxlength="20"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="service_payment_data.observation"
                          label="Observación"
                          dense
                          rows="1"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          @click.prevent="paymentSubmit"
                          small
                        >
                          <v-icon left> mdi-currency-usd </v-icon>
                          Generar cobro
                          {{ service_payment_data.bill ? " con factura" : "" }}
                          por
                          {{
                            numberFormat(
                              service_payment_data.insurance_pay_amount
                            )
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="service_payment_info_dialog"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card
        tile
        :disabled="service_payment_info_dialog_loading"
        :loading="service_payment_info_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="service_payment_info_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="service_payment_info_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `SERVICIO | ${service_payment_info_data.folio_full}`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. creación"
                        :value="service_payment_info_data.rs_service.created_at"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Asegurado"
                        :value="service_payment_info_data.insured_full_name"
                      />
                    </v-col>

                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Médico"
                        :value="
                          service_payment_info_data.rs_service.doctor.full_name
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="
                          service_payment_info_data.rs_service.specialty_type
                            .specialty_type
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="service_payment_info_data.provider_trade_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Monto"
                        :value="
                          numberFormat(service_payment_info_data.benefit_amount)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="
                        `COBRO | ${service_payment_info_data.folio} | ${
                          !service_payment_info_data.stamp_id ? 'SIN' : 'CON'
                        } FACTURA`
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left v-if="service_payment_info_data.stamp_id">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="cyan darken-3"
                          class="ml-1"
                          icon
                          @click.prevent="
                            downloadFile(
                              service_payment_info_data.stamp_id,
                              'pdf'
                            )
                          "
                        >
                          <v-icon> mdi-file-download </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Descargar PDF'" />
                    </v-tooltip>
                    <v-tooltip left v-if="service_payment_info_data.stamp_id">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="teal darken-2"
                          class="ml-1"
                          icon
                          @click.prevent="
                            downloadFile(
                              service_payment_info_data.stamp_id,
                              'xml'
                            )
                          "
                        >
                          <v-icon> mdi-xml </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Descargar XML'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Paciente | C. electrónico"
                        :value="service_payment_info_data.rs_service.rs.email"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Monto"
                        :value="
                          numberFormat(service_payment_info_data.pay_amount)
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Total cobrado"
                        :value="
                          numberFormat(
                            service_payment_info_data.insurance_pay_amount
                          )
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="service_payment_info_data.stamp_id" dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Razón social"
                        :value="service_payment_info_data.legal_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="RFC"
                        :value="service_payment_info_data.code_tax"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Código postal"
                        :value="service_payment_info_data.legal_zip"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Régimen"
                        :value="
                          service_payment_info_data.fiscal_regime.fiscal_regime
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Uso CFDI"
                        :value="service_payment_info_data.fiscal_use.fiscal_use"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Forma de pago"
                        :value="
                          service_payment_info_data.fiscal_method_payment
                            .fiscal_method_payment
                        "
                      />
                    </v-col>
                    <v-col
                      v-if="
                        service_payment_info_data.fiscal_method_payment_id ==
                          4 ||
                        service_payment_info_data.fiscal_method_payment_id == 18
                      "
                      cols="12"
                      xs="12"
                      md="3"
                    >
                      <ViewData
                        label="Núm. de aprobación"
                        :value="service_payment_info_data.approval_number"
                      />
                    </v-col>
                    <v-col
                      v-if="
                        service_payment_info_data.fiscal_method_payment_id ==
                          4 ||
                        service_payment_info_data.fiscal_method_payment_id == 18
                      "
                      cols="12"
                      xs="12"
                      md="3"
                    >
                      <ViewData
                        label="Tarjeta (4 últ. dig.)"
                        :value="service_payment_info_data.card"
                      />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Observación"
                        :value="service_payment_info_data.observation"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rss_pending_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile>
        <v-toolbar color="warning" dark dense>
          <v-toolbar-title> ALERTA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rss_pending_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3 text-center">
              <p>
                Existe{{ this.rss_pending > 0 ? "n " : " " }}
                {{ this.rss_pending }} caso{{
                  this.rss_pending > 0 ? "s " : " "
                }}
                del tipo "PREGUNTAS O COMENTARIOS".
              </p>
              <p>
                ¡Favor de atender est{{ rss_pending > 0 ? "os " : "e " }}
                lo antes posible!
              </p>
              <v-icon color="warning">mdi-alert</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  dateTimeNow,
  rules,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import { index, show } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      api: "servicios",
      page_route: "servicios",
      login: this.$store.getters.getLogin,
      loading: true,
      tbl: [],
      search: "",
      headers: [
        { text: "#", filterable: false, value: "index" },
        { text: "Folio", filterable: true, value: "folio" },
        { text: "Fecha", filterable: true, value: "created_at" },
        { text: "Asegurado", filterable: true, value: "insured_full_name" },
        { text: "Oficina", filterable: true, value: "insured.office_address" },
        { text: "Contratante", filterable: true, value: "contractor" },
        { text: "Tipo", filterable: true, value: "rs_type_name" },
        { text: "Cons. med. tel.", filterable: true, value: "phone_medical" },
        { text: "INFO", filterable: true, value: "external" },
        { text: "Seg. Méd.", filterable: true, value: "medical_revision" },
        {
          text: "Trat. Méd.",
          filterable: true,
          value: "insured.medical_treatment",
        },
        {
          text: "Servicios",
          filterable: true,
          value: "services",
        },
        {
          text: "",
          fixed: true,
          value: "action",
          sortable: false,
          width: "0px",
        },
        { text: "", value: "data-table-expand" },
      ],
      new_id: null,
      new_alert: false,
      letter: null,
      letter_dialog: false,
      active: 1,
      active_options: [
        { id: 1, value: "PENDIENTES" },
        { id: 0, value: "TERMINADAS" },
      ],
      pending: null,
      pending_options: [
        { id: null, value: "TODOS" },
        { id: 0, value: "EN PROCESO" },
        { id: 1, value: "PENDIENTES CONVENIO" },
        { id: 2, value: "PENDIENTES CONFIRMAR CONSULTA" },
      ],
      data: [],
      rss_search: {
        rs_type_id: 0,
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: "",
        start_date: "",
        end_date: "",
      },
      insurances: [],
      insurances_loading: true,
      rs_type_id: 0,
      rs_types: [],
      rs_types_loading: true,
      service_payment_dialog: false,
      service_payment_dialog_loading: false,
      service_payment_data: null,
      rules: rules(),
      fiscal_regimes: [],
      fiscal_regimes_loading: true,
      fiscal_uses: [],
      fiscal_uses_loading: true,
      fiscal_method_payments: [],
      fiscal_method_payments_loading: true,
      service_payment_info_dialog: false,
      service_payment_info_dialog_loading: false,
      service_payment_info_data: null,
      rss_pending: 0,
      rss_pending_dialog: false,
      btn_xls_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    widthActionColumn() {
      let w = 0;

      w += 44;
      w += 44;
      w += 42;
      w += 42;
      w += 42;

      const i = this.headers.findIndex((v) => v.value === "action");
      this.headers[i].width = `${w}px`;
    },
    getDataTable() {
      this.loading = true;

      let params =
        "?rs_type_id=" +
        this.rss_search.rs_type_id +
        "&pending=" +
        this.pending;

      if (!this.active) {
        params +=
          "&enrollment=" +
          this.rss_search.enrollment +
          "&name=" +
          this.rss_search.name +
          "&first_surname=" +
          this.rss_search.first_surname +
          "&second_surname=" +
          this.rss_search.second_surname +
          "&insurance_id=" +
          this.rss_search.insurance_id +
          "&start_date=" +
          this.rss_search.start_date +
          "&end_date=" +
          this.rss_search.end_date +
          "&operator=!=";
      }

      Axios.get(URL_API + "/rss" + params, headersToken(this.login.token)).then(
        (resp) => {
          this.tbl = resp.data.data;
          this.loading = false;

          this.rss_pending = resp.data.rss_pending;
          if (this.rss_pending > 0) {
            this.rss_pending_dialog = true;
          }
        }
      );
    },
    newRegistryAlert() {
      if (this.$route.params.new_id) {
        this.new_id = this.$route.params.new_id;
        this.new_alert = true;
      }

      this.letter = this.$route.params.letter
        ? this.$route.params.letter
        : null;

      if (this.new_id && this.letter) {
        this.letter_dialog = true;
      }
    },
    getColorBadge(counter, color) {
      if (counter !== 0) return color;
      return "grey";
    },
    getColorBadgeService(counter, pending, color) {
      if (counter !== 0) {
        if (pending === 0) {
          return color;
        } else {
          return "warning";
        }
      }

      return "grey";
    },
    dischargedSubmit(rs_id, folio) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma el terminar la solicitud de servicio (${folio})?`
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            let obj = {
              rs_id: rs_id,
              discharged: dateTimeNow(),
              updated_by_id: this.login.id,
            };

            Axios.post(
              URL_API + "/rss/discharge",
              obj,
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              res.data.success
                ? this.getDataTable()
                : console.log(res.data.message);
            });
          }
        });
    },
    getRSs() {
      if (this.active) {
        this.getDataTable();
      } else {
        this.tbl = [];
      }
    },
    itemRowBackground: function (item) {
      return item.pending ? "orange lighten-5" : "";
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    paymentDialog(item, i) {
      let service = item.services[i];
      let full_name =
        item.name + " " + item.first_surname + " " + item.second_surname;
      full_name = full_name.trim();

      this.service_payment_data = {
        id: null,
        active: true,
        pay_amount: parseFloat(service.benefits[0].amount),
        insurance_pay_amount: 0,
        bill: false,
        legal_name: full_name,
        code_tax: "",
        legal_zip: "",
        fiscal_regime_id: null,
        fiscal_use_id: null,
        fiscal_method_payment_id: null,
        rs_service_id: service.id,
        folio_full: service.folio_full,
        benefit_amount: service.benefits[0].amount,
        specialty_type: service.specialty_provider_type,
        doctor_name: service.doctor_provider_name,
        email: item.email,
        particular_payment: parseFloat(
          service.consulting_room_provider.particular_payment
        ),
        observation: null,
        approval_number: "",
        card: "",
        insured_full_name: full_name,
        created_at: service.created_at,
        provider_trade_name: service.provider_trade_name,
        reference: "",
        doctor_id: service.doctor_id,
      };

      this.paymentDialogInsurancePayAmount();

      this.service_payment_dialog_loading = false;
      this.service_payment_dialog = true;
    },
    paymentDialogInsurancePayAmount() {
      let amount =
        this.service_payment_data.pay_amount *
        (1 + this.service_payment_data.particular_payment / 100);

      amount = amount / 100;
      amount = Math.ceil(amount);
      amount = amount * 100;

      this.service_payment_data.insurance_pay_amount = amount;
    },
    paymentSubmit() {
      if (this.$refs.form_service_payment_data.validate()) {
        if (parseFloat(this.service_payment_data.pay_amount) >= 0.01) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma el cobro${
                  this.service_payment_data.bill
                    ? " con emisión de factura"
                    : ""
                } por ${this.numberFormat(
                  this.service_payment_data.insurance_pay_amount
                )}?`
              )
            )
            .then((resp) => {
              if (resp.isConfirmed) {
                this.service_payment_dialog_loading = true;

                Axios.post(
                  `${URL_API}/rss/services/insured/bills`,
                  this.service_payment_data,
                  headersToken(this.login.token)
                ).then((resp) => {
                  this.$swal.fire(
                    msgAlert(
                      resp.data.success ? "success" : "error",
                      resp.data.message
                    )
                  );

                  if (resp.data.success) {
                    this.service_payment_dialog = false;
                    this.getDataTable();
                  } else {
                    console.log(resp.data.message);
                  }

                  this.service_payment_dialog_loading = false;
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert("error", "Para continuar el monto debe ser mayor a $0.01")
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    paymentInfoDialog(rs_service_insured_bill_id) {
      this.service_payment_info_data = null;
      this.service_payment_info_dialog_loading = true;
      this.service_payment_info_dialog = true;

      Axios.get(
        `${URL_API}/rss/services/insured/bills/${rs_service_insured_bill_id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.service_payment_info_data = response.data.data;
        this.service_payment_info_dialog_loading = false;
      });
    },
    downloadFile(stamped_id, extension) {
      this.service_payment_info_dialog_loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/2`,
        headersToken(this.login.token)
      ).then((response) => {
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          const linkSource = `data:application/${extension};base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }

        this.service_payment_info_dialog_loading = false;
      });
    },
    downloadXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/rss/excel",
        {
          items: this.tbl,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.fileDownload(res.data.data, "xlsx", "servicios");
          this.btn_xls_ldg = false;
        } else {
          this.btn_xls_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    index("insurances", this.login.token, []).then((res) => {
      this.insurances = res.data;
      this.insurances_loading = false;
    });

    catalog("rs_types", this.login.token).then((res) => {
      this.rs_types = res;
      //this.rs_types.splice(1, 1);//PONER REEMBOLSO FASE 2

      if (this.login.role_id == 7) {
        this.rs_types = [
          { id: 1, type: "SERVICIOS", active: 1 },
          { id: 2, type: "REEMBOLSO", active: 0 }, //QUITAR REEMBOLSO FASE 2
        ];
      }

      this.rs_types.push({ id: 0, type: "TODOS", active: 1 });

      this.rs_types_loading = false;

      this.widthActionColumn();
      this.getDataTable();
      this.newRegistryAlert();
    });

    Axios.get(
      `${URL_API}/catalog?name=fiscal_regimes&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.fiscal_regimes = response.data.data;
      this.fiscal_regimes_loading = false;
    });
    Axios.get(
      `${URL_API}/catalog?name=fiscal_uses&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.fiscal_uses = response.data.data;
      this.fiscal_uses_loading = false;
    });
    Axios.get(
      `${URL_API}/catalog?name=fiscal_method_payments&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.fiscal_method_payments = response.data.data;
      this.fiscal_method_payments_loading = false;
    });

    const dateTime = dateTimeNow();
    this.rss_search.start_date = dateTime.substring(0, 10);
    this.rss_search.end_date = dateTime.substring(0, 10);
  },
};
</script>

<style>
.m-btn {
  margin-right: 5px !important;
}
.m-btn-badge {
  margin-right: 7px !important;
}
.fs-small {
  font-size: 0.755rem !important;
}
.text-start {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>